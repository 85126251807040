<template>
  <div class="my">
    <div style="background: #000000;height: 100%">
        <div class="my-head">
            <div class="user-info">
                <div>您好，{{showName}}</div>
                <!-- <div style="margin-top:10px">123****123</div> -->
            </div>
            <div class="out-button" @click="getLogOut">
                <span>退出</span>
            </div>
        </div>
        <div class="my-fun">
            <div class="fun-item">
                <div class="item" @click="onBankCard">
                    <div class="item-title">
                        <img src="@/assets/img/yinhangka.png" alt="">
                        我的银行卡
                    </div>
                    <div><van-icon name="arrow" /></div>
                </div>
                <div class="item" @click="onOrder">
                    <div class="item-title">
                        <img src="@/assets/img/dingdan.png" alt="">
                        我的订单
                    </div>
                    <div><van-icon name="arrow" /></div>
                </div>
                <div class="item" @click="feedback">
                    <div class="item-title">
                        <img src="@/assets/img/tousufankui.png" alt="">
                        订单异议反馈
                    </div>
                    <div><van-icon name="arrow" /></div>
                </div>
                <div class="item" @click="onAboutUs">
                    <div class="item-title">
                        <img src="@/assets/img/women.png" alt="">
                        <span>关于我们</span>
                    </div>
                    <div><van-icon name="arrow" /></div>
                </div>
            </div>
        </div>
        <div class="advertising">
            <img src="@/assets/img/advertising.png" alt="">
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      showName: localStorage.getItem('realName') || localStorage.getItem('nickname')
    };
  },
  methods: {
    onBankCard(){
      this.$router.push('/myBankCard')
    },
    onOrder(){
      this.$router.push('/myOrder')
    },
    onAboutUs(){
      this.$router.push('/aboutUsWe')
    },
    getLogOut(){
      this.$router.replace('/login')
      localStorage.setItem("manage_temp_no", "")
      localStorage.setItem("queryId", "")
      localStorage.clear();
    },
    feedback(){
      this.$router.push('/feedback')
    }
  },
};
</script>
<style scoped lang="scss">
.my {
  height: 100%;
  color: #000000;
  .my-head{
    display: flex;
    align-items: center;
    background-image: url("../../assets/img/my_top_bg.png");
    background-size: cover;
    color:#fff;
    padding: 40px 26px 50px;
    font-size: 34px;
    .user-info{
      flex: 1;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 40px;
      margin-left: 20px;
    }
    .out-button{
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 32px;
      span{
        border: 2px solid #fff;
        padding: 8px 24px;
        border-radius: 25px;
      }
    }
  }
  .my-fun{
    background-image: url("../../assets/img/my_top_bg.png");
    background-size: cover;
    .fun-item{
      margin: 0px 30px;
      background: #fff;
      padding: 0px 30px;
      border-radius: 14px;
      .item{
        padding: 40px 0px;
        border-bottom: 1px solid #979797 ;
        display: flex;
        align-items: center;
        font-size: 28px;
        line-height: 28px;
        .item-title{
          flex: 1;
          display: flex;
          align-items: center;
          img{
            width: 30px;
            margin-right: 20px;
          }
        }
      }
      .item:last-child{
        border-bottom: 0px;
      }
      
    }
  }
  .advertising{
    margin: 30px;
    img{
      width: 100%;
      height: auto;
      border-radius: 14px;
    }
  }
}
</style>